.header {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 10px;
  margin-left: 15px;
  margin-bottom: 30px;
}

.logo {
  width: 120px
}

.login-text {
  text-decoration: underline;
  font-size: 16px;
  color: #111;
}

.title {
  font-size: 46px;
  color: #393f44;
  /* margin-left: 10px; */
}

.sub-title {
  font-weight: lighter;
  font-size: 20px;
  /* top: -30px; */
  /* margin-left: 10px; */
}

.fb-logo {
  font-size: 33px;
  text-align: left;
}

.contact {
  font-size: 30px;
  padding-top: 10px;
  color: #393f44;
}

.contact-2 {
  font-size: 18px;
  padding-top: 10px;
  color: #393f44;
}

.links {
  text-decoration: none;
  padding-top: 20px;
  padding-left: 50px;
  /* text-align: center; */
  color: black;
}

@media (max-width: 1590px) and (min-width: 1400px) {


  .title {
    padding-top: 5px;
    font-size: 40px;
    color: #393f44;
    /* margin-left: 10px; */
  }

  .contact {
    font-size: 26px;
    /* padding-top: 10px; */
    color: #393f44;
  }

  .sub-title {
    font-weight: lighter;
    top: -30px;
    padding-left: 10px;
  }

  .fb-logo {
    font-size: 33px;
    text-align: center;
  }
}

@media (max-width: 1399px) and (min-width: 1201px) {
  .header {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 10px;
    margin-left: 100px;
    margin-bottom: 30px;
  }

  .title {
    padding-top: 5px;
    font-size: 34px;
    color: #393f44;
    /* margin-left: 10px; */
    /* margin-left: 10px; */
  }

  .links {
    text-decoration: none;
    padding-top: 20px;
    padding-left: 30px;
    /* text-align: center; */
    color: black;
  }

  .contact {
    font-size: 27px;
    /* padding-top: 10px; */
    color: #393f44;
  }


}

@media (max-width: 1200px) and (min-width: 992px) {

  .title {
    padding-top: 5px;
    font-size: 30px;
    color: #393f44;
    /* margin-left: 10px; */
    /* margin-left: 10px; */
  }

  .links {
    text-decoration: none;
    padding-top: 20px;
    padding-left: 10px;
    /* text-align: center; */
    color: black;
  }

  .fb-logo {
    font-size: 33px;
    text-align: left;
  }
}

@media (max-width: 991px) and (min-width: 750px) {

  .logo {
    width: 120px;
  }

  .sub-title {
    display: none;
  }

  .contact-2 {
    display: none;
  }

  .title {
    padding-top: 13px;
    font-size: 32px;
    color: #393f44;
    /* margin-left: 10px; */
    /* margin-left: 10px; */
  }

  .links {
    text-decoration: none;
    padding-top: 20px;
    padding-left: 0px;
    /* text-align: center; */
    color: black;
  }

  .fb-logo {
    font-size: 33px;
    text-align: left;
  }

  .contact {
    font-size: 23px;
    /* padding-top: 10px; */
    color: #393f44;
    padding-top: 10px;
  }
}

@media (max-width: 750px) {

  .contact-2 {
    display: none;
  }

  .logo {
    width: 85px;
  }

  .title {
    padding-top: 13px;
    font-size: 22px;
    color: #393f44;
    /* margin-left: -30px; */
    /* margin-left: 10px; */
    /* margin-left: 10px; */
  }

  .sub-title {
    display: none;
  }

  .ls-block {
    display: none;
  }


  .links {
    text-decoration: none;
    padding-top: 20px;
    padding-left: 0px;
    text-align: left;
    color: black;
  }

  .fb-logo {
    display: none;
  }

  .contact {
    font-size: 18px;
    /* padding-top: 10px; */
    color: #393f44;
    padding-top: 10px;
  }

  .contact-2 {
    display: none;
  }
}