.phone-input,
.subject-input,
.message-input,
.name-input,
.email-input {
  font-weight: 100;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  padding: 8px;
}
.message-sent {
  height: 346px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.loading-spinner {
  height: 338px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.send-button {
  background-color: #393f44;
  color: white;
  padding: 5px;
  width: 100%;
  /* margin-left: 11px; */
}
.sent-icon {
  height: 75px;
}
.sent-message {
  color: green;
  font-weight: bold;
  /* font-size: 24px; */
}

.failed-sent-message {
  color: red;
  font-weight: bold;
}
@media (max-width: 1200px) and (min-width: 992px) {
  .sent-message {
    font-size: 16px;
  }
  .sent-icon {
    height: 60px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .sent-message {
    font-size: 16px;
  }
  .sent-icon {
    height: 60px;
  }
}
@media (max-width: 767px) {
  .phone-input,
  .subject-input,
  .message-input,
  .name-input,
  .email-input {
    font-weight: 100;
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    padding: 8px;
  }

  .send-button {
    background-color: #393f44;
    color: white;
    /* padding: 5px; */
    /* width: 94% !important; */
  }
  .sent-message {
    font-size: 16px;
  }
  .sent-icon {
    height: 60px;
  }

  .message-sent {
    height: 395px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .loading-spinner {
    height: 385px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
