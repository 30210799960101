body {
  overflow-x: hidden;
}

.body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 5px;
}

.rates-row {
  margin-top: 50px;
  /* position: relative;
  bottom: 400px; */
}

.grass-image {
  width: 100%;
  height: 45vh;
}

.body-row {
  background-color: #f6f6f6;
  padding-top: 30px;
  padding-bottom: 15px;
  /* position: relative;
  bottom: 400px; */
}

.rates {
  padding: 15px;
  color: white;
  font-size: 40px;
  /* position: relative;
  bottom: 400px; */
  /* padding-left: 150px; */
}

.body-title {
  font-size: 28px;
  margin-top: 10px;
  /* margin-left: 30px; */
}

.options {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  /* margin-left: 30px; */
}

.list {
  font-size: 18px;
  /* font-weight: bold; */
  /* margin-top: 15px; */
  /* margin-left: 30px; */
}

.list-button,
.submit-button {
  /* margin-top: 15px; */
  margin-bottom: 15px;
  /* margin-left: 30px; */
  background-color: #393f44;
  /* padding: 5px 10px 5px 10px; */
}

.list-button-text {
  font-size: 13px;
  padding: 5px 15px 5px 15px;
}

.fb-logo-2 {
  font-size: 50px;
  /* color: #3d5a98; */
  margin-left: 20px;
}

.fb-text {
  color: #ea52bc;
  font-weight: bold;
}

.footer-text {
  background-color: #414141;
  padding: 15px;
  color: #fff;
}

.chat-button {
  z-index: 99;
  position: fixed;
  bottom: 10px;
  right: 20px;
  background-color: #393f44;
  border-radius: 8px;
  border: none;
  padding: 8px 25px;
  color: white;
}

.chat-button-text {
  /* background-color: #393f44; */
  padding: 8px 25px;
}

.contact-row {
  margin-bottom: 130px;
}

#video-container {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;
}

#background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.text-overlay {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  /* display: flex; */
}

.content {
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.formatted-link {
  text-decoration: none;
  color: #000;
}

.formatted-link-footer {
  text-decoration: none;
  color: #fff;
}

.formatted-link-footer:hover {
  text-decoration: underline;
  color: #fff;
}

/* Media queries */

@media (max-width: 1601px) and (min-width: 1413px) {
  .rates {
    padding: 15px;
    color: white;
    font-size: 44px;
    /* position: relative;
  bottom: 400px; */
    /* padding-left: 150px; */
  }

  .fb-text {
    color: #ea52bc;
    font-weight: bold;
    font-size: 15px;
  }
}

@media (max-width: 1412px) and (min-width: 1201px) {
  .rates {
    padding: 15px;
    color: white;
    font-size: 42px;
    /* margin-left: 250px; */
  }

  .contact-row {
    margin-left: 30px;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .rates {
    padding: 15px;
    color: white;
    font-size: 40px;
    /* margin-left: 250px; */
  }

  .fb-text {
    font-size: 13px;
    color: #ea52bc;
    font-weight: bold;
  }



  .contact-title {
    font-size: 24px;
    padding-left: 5px;
  }

  .contact-details {
    font-size: 16px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .rates {
    padding: 15px;
    color: white;
    font-size: 38px;
    /* margin-left: 250px; */
  }

  .fb-text {
    font-size: 12px;
    color: #ea52bc;
    font-weight: bold;
  }

  .contact-title {
    font-size: 20px;
    padding-left: 5px;
  }

  .contact-details {
    font-size: 16px;
  }

  .list {
    font-size: 16px;
  }

  .options {
    font-size: 16px;
  }



  .body-title {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .rates {
    padding-top: 55px;
    color: white;
    font-size: 25px;
    /* margin-left: 25px; */
  }

  .list {
    font-size: 16px;
  }

  .options {
    font-size: 16px;
  }

  .fb-text {
    font-size: 12px;
    color: #ea52bc;
    font-weight: bold;
  }

  .contact-title {
    font-size: 25px;
    padding-left: 5px;
  }

  .contact-details {
    font-size: 16px;
  }

  .fb-text {
    display: none;
  }



  .body-title {
    font-size: 20px;
  }

  .image-pin {
    display: none;
  }
}

@media (max-width: 450px) {
  .rates {
    padding-top: 55px;
    color: white;
    font-size: 21px;
    /* margin-left: 25px; */
  }
}